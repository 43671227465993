<template>
  <div class="multilevel user-login" v-if="tableData.length > 0">
    <el-table :data="tableData" :height="height + 26" style="width: 100%">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column
        prop="companyName"
        label="所属企业"
        width="180"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="loginName"
        label="账号"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="userName"
        label="用户姓名"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        v-for="(item, index) in tableData[0].detailList"
        :key="index"
        :label="item.statisticsDay"
        :width="tableData[0].detailList.length > 10 ? '130' : ''"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">{{
          scope.row.detailList[index].signDuration
        }}</template>
      </el-table-column>
      <el-table-column
        prop="count"
        label="总在线时长"
        :show-overflow-tooltip="true"
        width="120"
      ></el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="pageObj.pageSize"
        :current-page="pageObj.currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number
    },
    tableData: {
      type: Array
    },
    total: {
      type: Number
    }
  },
  watch: {
    height (val, oldVal) {
      setTimeout(() => {
        this.setTableHeight(
          this.tableData.length > 0 ? this.tableData[0].detailList.length : 0,
          this.tableData.length > 0 ? this.tableData.length : 0
        )
      }, 200)
    }
  },

  data () {
    return {
      pageObj: {
        currentPage: 1,
        pageSize: 10
      }
    }
  },

  methods: {
    handleSizeChange (val) {
      this.pageObj.pageSize = val
      this.$emit('sendInfo', this.pageObj)
    },
    handleCurrentChange (val) {
      this.pageObj.currentPage = val
      this.$emit('sendInfo', this.pageObj)
    },
    reset () {
      this.pageObj.currentPage = 1
      this.pageObj.pageSize = 10
    },
    setTableHeight (Xlength, Ylength) {
      setTimeout(() => {
        this.tableHeight = this.height
        if (Xlength > 7) {
          document
            .querySelector('.user-login .el-table')
            .classList.add('el-table--scrollable-x')
          document.querySelector(
            '.user-login .el-table--scrollable-x .el-table__body-wrapper'
          ).style = `height:${this.height - 23}px`
        }
      }, 300)

      if (Ylength > 10) {
        setTimeout(() => {
          document
            .querySelector('.user-login .el-table')
            .classList.add('el-table--scrollable-y')
          document.querySelector(
            '.user-login .el-table .el-table__body-wrapper'
          ).style = `height:${this.height - 23}px`
        }, 500)
      }
    }
  },
  mounted () {
    this.tableHeight = this.height
  }
}
</script>