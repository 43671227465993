<template>
  <div class="user-login-statis" ref="userLoginStatis">
    <el-tabs type="border-card" @tab-click="clickTab" v-model="form.type">
      <el-tab-pane label="统计" name="1">
        <div class="form-area" ref="form">
          <analysis-form
            @getFormVal="onSubmit"
            :showCar="false"
            :requireCompany="true"
          >
            <el-button
              type="primary"
              size="small"
              :disabled="comArr.length === 0"
              :loading="downLoadStatus"
              @click="exportData(1)"
              >导出</el-button
            >
          </analysis-form>
        </div>
        <!-- 企业统计 -->
        <div class="form-table" v-if="comArr.length > 0">
          <el-table :data="comArr" :height="tableHeight" stripe class="left">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column
              prop="companyName"
              label="所属企业"
              :show-overflow-tooltip="true"
              width="200px"
            ></el-table-column>
            <el-table-column
              v-for="item in comArr[0].detailList"
              :key="item.statisticsDay"
              :label="item.statisticsDay"
              :show-overflow-tooltip="true"
              :width="comArr[0].detailList.length > 10 ? '130' : ''"
            >
              <template>{{ item.signDuration }}</template>
            </el-table-column>
            <el-table-column
              prop="count"
              label="总在线时长（小时）"
              :show-overflow-tooltip="true"
              width="150px"
            ></el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="详情" name="2">
        <div class="form-area" ref="form1">
          <analysis-form
            @getFormVal="onSubmit"
            :showCar="false"
            :showUserName="true"
            :requireCompany="true"
          >
            <el-button
              type="primary"
              size="small"
              :disabled="carArr.length === 0"
              :loading="downLoadStatus"
              @click="exportData(2)"
              >导出</el-button
            >
          </analysis-form>
        </div>
        <!-- 车辆明细 -->
        <car-table
          ref="tableDetail"
          :height="tableHeight"
          :total="total"
          :tableData="carArr"
          @sendInfo="getPageInfo"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import analysisForm from '@/components/analysisForm/analysisForm.vue'
import carTable from './components/carTable.vue'
import {
  queryUserLoginHistoryToCompany,
  queryUserLoginHistoryCountDetail
} from '@/api/lib/gps-api.js'
import { addExcelExport } from '@/api/lib/refreshAlarm.js'
import { getCurent, formatDate } from '@/common/utils/index'

export default {
  name: 'userLoginStatis',
  components: {
    analysisForm,
    carTable
  },
  data () {
    return {
      tableHeight: 0,
      tableData: [],
      state: 0,
      form: {
        type: '1',
        companyId: null,
        beginTime: null,
        endTime: null,
        currentPage: 1,
        pageSize: 10
      },
      carArr: [], //   车辆明细
      comArr: [],
      total: 0,
      modelObj: null,
      exportForm: {},
      downLoadStatus: false
    }
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.userLoginStatis.clientHeight
      const formHeight = this.$refs.form.clientHeight || 64
      this.tableHeight = wholeHeight - formHeight - 40 - 30 - 40 - 24
    },

    //导出
    exportData (key) {
      this.downLoadStatus = true
      delete this.exportForm.currentPage
      delete this.exportForm.pageSize
      let data = {
        baseUrl: 'report',
        fileName:
          key === 1
            ? `用户登录统计-${getCurent()}`
            : `用户登录详情-${getCurent()}`,
        queryParam: JSON.stringify(this.exportForm),
        queryPath:
          key === 1
            ? `/manageStatisticsExport/queryLoginHistoryCompanyExport`
            : `/manageStatisticsExport/queryLoginHistoryUserExport`
      }
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.downLoadStatus = false
          this.$store.dispatch('setRefreshStatus', true)
        })
        .catch(() => {
          this.downLoadStatus = false
        })
    },

    // 提交
    onSubmit (value) {
      this.form = { ...this.form, ...value }
      this.form.currentPage = 1
      this.getDataList()
    },

    // 获取列表
    getDataList () {
      let data = { ...this.form }
      this.comArr = []
      delete data.type
      switch (this.form.type) {
        case '1':
          /**获取企业统计*/
          queryUserLoginHistoryToCompany(data).then((res) => {
            if (res.code === 1000) {
              this.comArr = res.data.list
            } else {
              this.comArr = []
            }
          })
          break
        case '2':
          /**获取车辆详情*/
          queryUserLoginHistoryCountDetail(data).then((res) => {
            if (res.code === 1000) {
              this.carArr = res.data.list
              this.total = res.data.total
            } else {
              this.carArr = []
              this.total = 0
            }
            this.$nextTick(() => {
              this.$refs.tableDetail.setTableHeight(
                res.data.list ? res.data.list[0].detailList.length : 0,
                res.data.list ? res.data.list.length : 0
              )
            })
          })
          break
      }
      this.exportForm = { ...data }
    },
    /**切换统计|详情 */
    clickTab (tab, event) {
      // if (this.$refs.tableDetail) this.$refs.tableDetail.setTableHeight()
    },
    getPageInfo (val) {
      this.form.pageSize = val.pageSize
      this.form.currentPage = val.currentPage
      if (this.carArr.length === 0) return
      this.getDataList()
    }
  },
  created () { },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.user-login-statis {
  height: 100%;
  width: 100%;
  .el-tabs {
    height: 100%;
  }
}
.theme-project-gps {
  .user-login-statis {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
